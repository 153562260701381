<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 450px;
}
.jiehsao {
  position: relative;
  top: -300px;
  z-index: 2;
  width: 1200px;
  min-height: 600px;
  margin: 0 auto;
  background-color: #fff;
  padding: 80px;
  box-sizing: border-box;
  .jie_tit {
    font-size: 32px;
    margin-bottom: 60px;
  }
  .jie_sedc {
    font-size: 16px;
    line-height: 2;
  }
}
.map {
  position: relative;
  width: 100%;
  height: 736px;
}
</style>
<template>
  <div>
    <baidu-map class="map" :center="position" :zoom="16">
      <img class="img" src="../../assets/img/home/dt.png" alt />
      <bm-info-window
        :closeOnClick="infoWindow.flag"
        :autoPan="infoWindow.autoPan"
        :position="position"
        :show="infoWindow.show"
      >
        <p v-text="infoWindow.contents"></p>
      </bm-info-window>
      <!-- <bm-marker
        :position="{ lng: 113.607395, lat: 34.802485 }"
        :dragging="false"
        animation="BMAP_ANIMATION_BOUNCE"
      >
      </bm-marker> -->
    </baidu-map>

    <div class="jiehsao">
      <div class="jie_tit">公司介绍</div>
      <div class="jie_sedc" v-html="company"></div>
    </div>
  </div>
</template>
<script>
import { about } from "../../assets/js/index";
export default {
  components: {},
  data() {
    return {
      infoWindow: {
        show: true,
        flag: false,
        autoPan: false,
        contents: "",
      },
      company: "",
      position: {
        lng: "",
        lat: "",
      },
    };
  },
  mounted() {
    this.getAbout();
  },
  methods: {
    getAbout() {
      about({
        siteId: window.localStorage.getItem("siteId"),
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.infoWindow.contents = res.data.address;
          this.company = res.data.company;
          let str = res.data.map.split(",");
          this.position.lng = str[0];
          this.position.lat = str[1];
        }
      });
    },
    getLocationPoint(e) {
      // this.lng = e.point.lng;
      // this.lat = e.point.lat;
      /* 创建地址解析器的实例 */
      let geoCoder = new BMap.Geocoder();
      /* 获取位置对应的坐标 */
      geoCoder.getPoint(this.addressKeyword, (point) => {
        this.selectedLng = point.lng;
        this.selectedLat = point.lat;
      });
    },
  },
};
</script>